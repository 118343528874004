// src/components/RecipeRunner.js
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Tabs, Card, Button, Typography, message, Spin, Input } from 'antd';
import {
  PlayCircleOutlined,
  ArrowLeftOutlined,
  ClearOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined
} from '@ant-design/icons';
import { apiService } from '../services/apiService';
import { buildRequestData } from '../utils/requestBuilder';
import ApiForm from './ApiForm';

const { Title, Text, Paragraph } = Typography;
const { TabPane } = Tabs;

function RecipeRunner({ recipe, onClose, selectedEnv, devIp }) {
  const [logs, setLogs] = useState([]);
  const [stepIndex, setStepIndex] = useState(0);
  const [stepResponses, setStepResponses] = useState([]);

  // allInOneOverrides holds the **raw** form data from ApiForm
  const [allInOneOverrides, setAllInOneOverrides] = useState({});

  // Terminal/Console states
  const [currentCommand, setCurrentCommand] = useState('');
  const [history, setHistory] = useState([]);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const inputRef = useRef(null);

  const [isFullScreen, setIsFullScreen] = useState(false);

  // API Config states
  const [apiConfigs, setApiConfigs] = useState([]);
  const [loadingConfigs, setLoadingConfigs] = useState(false);

  // -------------------------------------------
  // Clear Logs
  // -------------------------------------------
  const handleClearLogs = () => {
    setLogs([]);
    message.success('Logs have been cleared.');
  };

  // -------------------------------------------
  // Terminal Input Handlers
  // -------------------------------------------
  const handleCommandEnter = () => {
    const command = currentCommand.trim();
    if (command === '') return;
    addLog(`> ${command}`, 'command');

    setHistory((prev) => [...prev, command]);
    setHistoryIndex(-1);
    executeCommand(command);
    setCurrentCommand('');
  };

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (history.length === 0) return;
      const newIndex =
        historyIndex === -1 ? history.length - 1 : historyIndex - 1;
      if (newIndex >= 0) {
        setHistoryIndex(newIndex);
        setCurrentCommand(history[newIndex]);
      }
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (history.length === 0) return;
      if (historyIndex === -1) return;
      const newIndex = historyIndex + 1;
      if (newIndex < history.length) {
        setHistoryIndex(newIndex);
        setCurrentCommand(history[newIndex]);
      } else {
        setHistoryIndex(-1);
        setCurrentCommand('');
      }
    }
  };

  // -------------------------------------------
  // Full Screen Toggle for Console
  // -------------------------------------------
  useEffect(() => {
    if (isFullScreen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isFullScreen]);

  useEffect(() => {
    const handleEsc = (e) => {
      if (e.key === 'Escape' && isFullScreen) {
        setIsFullScreen(false);
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => window.removeEventListener('keydown', handleEsc);
  }, [isFullScreen]);

  // -------------------------------------------
  // Auto-scroll logs
  // -------------------------------------------
  const logsEndRef = useRef(null);
  useEffect(() => {
    if (logsEndRef.current) {
      logsEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [logs]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [logs]);

  // -------------------------------------------
  // Terminal Command Execution
  // -------------------------------------------
  const executeCommand = (command) => {
    const args = command.split(' ');
    const baseCommand = args[0].toLowerCase();

    switch (baseCommand) {
      case 'run':
        handleRunCommand(args.slice(1));
        break;
      case 'clear':
        handleClearLogs();
        break;
      case 'help':
        displayHelp();
        break;
      default:
        addLog(`Unknown command: ${command}`, 'error');
    }
  };

  const handleRunCommand = (args) => {
    if (args.length === 0) {
      addLog(`Please specify a subcommand for "run": auto, all, step-by-step, step [n]`, 'error');
      return;
    }

    const subCommand = args[0].toLowerCase();
    switch (subCommand) {
      case 'auto':
        handleAutoRunAll();
        break;
      case 'all':
        handleAllInOneRunAll();
        break;
      case 'step-by-step':
        handleStepByStepRun();
        break;
      case 'step':
        if (args.length < 2) {
          addLog(`Usage: run step [number]`, 'error');
          return;
        }
        const stepNumber = parseInt(args[1], 10);
        if (isNaN(stepNumber) || stepNumber < 1 || stepNumber > recipe.steps.length) {
          addLog(`Invalid step number: ${args[1]}`, 'error');
          return;
        }
        handleRunSpecificStep(stepNumber - 1);
        break;
      default:
        addLog(`Unknown run subcommand: ${subCommand}`, 'error');
    }
  };

  const displayHelp = () => {
    addLog(`Available commands:`, 'info');
    addLog(`- run auto: Execute all steps automatically (no user input)`, 'info');
    addLog(`- run all: Fill each step in the UI, then run them all.`, 'info');
    addLog(`- run step-by-step: Manually run each step.`, 'info');
    addLog(`- run step [n]: Execute only step #n.`, 'info');
    addLog(`- clear: Clear terminal logs.`, 'info');
    addLog(`- help: Show this help.`, 'info');
  };

  // -------------------------------------------
  // Initialize
  // -------------------------------------------
  useEffect(() => {
    // If we have recipe steps, create initial overrides from recipe's requestOverrides
    if (recipe?.steps) {
      const initial = {};
      recipe.steps.forEach((step) => {
        const key = step._id || `step_${step.stepNumber}`;
        // Start with the step's requestOverrides
        initial[key] = step.requestOverrides || {};
      });
      setAllInOneOverrides(initial);
    }
  }, [recipe]);

  useEffect(() => {
    // Fetch all API configs once
    const fetchConfigs = async () => {
      setLoadingConfigs(true);
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/config`);
        setApiConfigs(res.data || []);
      } catch (err) {
        console.error('Failed to fetch API configs:', err);
        message.error('Could not load API configs');
      } finally {
        setLoadingConfigs(false);
      }
    };
    fetchConfigs();
  }, []);

  // -------------------------------------------
  // Early exit if no recipe
  // -------------------------------------------
  if (!recipe) {
    return (
      <Card>
        <Title level={4}>No Recipe Selected</Title>
        <Button onClick={onClose}>Back</Button>
      </Card>
    );
  }

  // -------------------------------------------
  // Logging Helper
  // -------------------------------------------
  const addLog = (msg, type = 'info') => {
    setLogs((prev) => [...prev, { timestamp: new Date().toLocaleTimeString(), msg, type }]);
  };

  // -------------------------------------------
  // Dynamic Mappings
  // -------------------------------------------
  const applyDynamicMappings = (step, priorResponses) => {
    const { dynamicMappings = [] } = step;
    let merged = { ...step.requestOverrides };

    dynamicMappings.forEach((mapping) => {
      // fromStep=1 => find step #1
      const fromIndex = recipe.steps.findIndex((s) => s.stepNumber === mapping.fromStep);
      if (fromIndex < 0) return;
      const fromResp = priorResponses[fromIndex];
      if (!fromResp) return;

      let value = fromResp;
      mapping.fromPath.split('.').forEach((part) => {
        if (value && value[part] !== undefined) {
          value = value[part];
        }
      });

      let root = merged;
      const pathParts = mapping.to.split('.');
      for (let i = 0; i < pathParts.length - 1; i++) {
        const p = pathParts[i];
        if (!root[p]) root[p] = {};
        root = root[p];
      }
      root[pathParts[pathParts.length - 1]] = value;
    });

    return merged;
  };

  // -------------------------------------------
  // Single Step Runner
  // -------------------------------------------
  const runSingleStep = async (index, priorResponses, userRawForm) => {
    const step = recipe.steps[index];
    addLog(`Running Step #${step.stepNumber}...`);

    // 1) Find the matching ApiConfig
    const apiConfig = apiConfigs.find((cfg) => cfg._id === step.apiConfigId);
    if (!apiConfig) {
      addLog(`Step #${step.stepNumber} ERROR: API Config not found`, 'error');
      throw new Error(`No ApiConfig for step ${step.stepNumber}`);
    }

    // 2) Start with step.requestOverrides + dynamic mappings
    let merged = applyDynamicMappings(step, priorResponses);

    // 3) Convert userRawForm from { include, values, ... } → final shape
    const useRawBody = userRawForm?.useRawBody || false;
    const rawBodyValue = userRawForm?.rawBody || '{}';

    // Build final user-based data
    const userRequestData = buildRequestData(
      userRawForm,            // raw form data
      apiConfig.parameters,   // param definitions
      apiConfig,
      useRawBody,
      rawBodyValue
    );

    // 4) Merge userRequestData with the step’s overrides
    merged = { ...merged, ...userRequestData };

    // **LOG** what we’re about to send:
    console.log('✅ [runSingleStep] FINAL MERGED DATA →', merged);

    try {
      // 5) Make the actual API call
      const response = await apiService(
        apiConfig.route,
        merged,
        selectedEnv,
        devIp,
        apiConfig
      );
      priorResponses[index] = response.data;

      addLog(`Step #${step.stepNumber} SUCCESS: ${JSON.stringify(response.data)}`, 'success');
      return response.data;
    } catch (err) {
      addLog(`Step #${step.stepNumber} ERROR: ${err.message}`, 'error');
      throw err;
    }
  };

  // -------------------------------------------
  // Run Modes
  // -------------------------------------------
  // 1) Auto Run
  const handleAutoRunAll = async () => {
    addLog(`=== Auto-Run for Recipe: ${recipe.name} ===`);
    let responses = [];
    try {
      for (let i = 0; i < recipe.steps.length; i++) {
        // no user input in auto mode
        await runSingleStep(i, responses, {});
      }
      addLog(`All steps completed!`, 'success');
    } catch (err) {
      addLog(`Auto-Run halted: ${err.message}`, 'error');
    }
  };

  // 2) All-in-One
  const handleAllInOneRunAll = async () => {
    addLog(`=== All-In-One for Recipe: ${recipe.name} ===`);
    let responses = [];
    try {
      for (let i = 0; i < recipe.steps.length; i++) {
        const step = recipe.steps[i];
        const key = step._id || `step_${step.stepNumber}`;
        const userOverrides = allInOneOverrides[key] || {};

        await runSingleStep(i, responses, userOverrides);
      }
      addLog(`All steps (All-in-One) completed!`, 'success');
    } catch (err) {
      addLog(`All-in-One halted: ${err.message}`, 'error');
    }
  };

  // 3) Step-by-Step
  const handleStepByStepRun = () => {
    addLog(`=== Step-by-Step Run Initiated ===`, 'info');
  };

  const handleRunSpecificStep = async (index) => {
    try {
      const userData =
        allInOneOverrides[recipe.steps[index]._id || `step_${recipe.steps[index].stepNumber}`] ||
        {};
      await runSingleStep(index, stepResponses, userData);
      addLog(`Step #${recipe.steps[index].stepNumber} completed successfully.`, 'success');
    } catch (err) {
      addLog(`Error on step #${recipe.steps[index].stepNumber}: ${err.message}`, 'error');
    }
  };

  const handleRunCurrentStep = async () => {
    try {
      const newResponses = [...stepResponses];
      const step = recipe.steps[stepIndex];
      const key = step._id || `step_${step.stepNumber}`;
      const userOverrides = allInOneOverrides[key] || {};

      await runSingleStep(stepIndex, newResponses, userOverrides);
      setStepResponses(newResponses);
    } catch (err) {
      // already logged
    }
  };

  const nextStep = () => {
    if (stepIndex < recipe.steps.length - 1) {
      setStepIndex(stepIndex + 1);
    }
  };
  const prevStep = () => {
    if (stepIndex > 0) {
      setStepIndex(stepIndex - 1);
    }
  };

  // -------------------------------------------
  // Helpers
  // -------------------------------------------
  const getApiConfigById = (id) => {
    return apiConfigs.find((cfg) => cfg._id === id) || null;
  };

  // -------------------------------------------
  // Render All-in-One
  // -------------------------------------------
  const renderAllInOneForms = () => {
    return recipe.steps.map((step) => {
      const key = step._id || `step_${step.stepNumber}`;
      const matchedConfig = getApiConfigById(step.apiConfigId);

      if (loadingConfigs) {
        return (
          <Card style={{ marginBottom: 16 }} key={`loading-${key}`}>
            <Spin /> Loading API Config...
          </Card>
        );
      }
      if (!matchedConfig) {
        return (
          <Card style={{ marginBottom: 16 }} key={`nocfg-${key}`}>
            <Title level={5}>Step {step.stepNumber}</Title>
            <Text type="danger">No matching config for ID: {step.apiConfigId}</Text>
          </Card>
        );
      }

      return (
        <Card style={{ marginBottom: 16, background: '#fafafa' }} key={key}>
          <Title level={5}>Step {step.stepNumber}</Title>
          <Text level={4}>{matchedConfig.name}</Text>
          <Paragraph type="secondary">{matchedConfig.description}</Paragraph>

          <ApiForm
            hideFormButtons
            selectedApi={matchedConfig}
            initialRequestParams={allInOneOverrides[key] || {}}
            onValuesChange={(changedValues, allValues) => {
              // Store raw form data in allInOneOverrides
              setAllInOneOverrides((prev) => ({
                ...prev,
                [key]: allValues
              }));
            }}
          />
        </Card>
      );
    });
  };

  // -------------------------------------------
  // Render Step-by-Step
  // -------------------------------------------
  const renderStepByStepForm = () => {
    const step = recipe.steps[stepIndex];
    const key = step._id || `step_${step.stepNumber}`;
    const matchedConfig = getApiConfigById(step.apiConfigId);

    if (loadingConfigs) {
      return (
        <Card style={{ marginBottom: 16 }}>
          <Spin /> Loading API Config...
        </Card>
      );
    }
    if (!matchedConfig) {
      return (
        <Card style={{ marginBottom: 16 }}>
          <Title level={5}>Step {step.stepNumber}</Title>
          <Text type="danger">No matching config for ID: {step.apiConfigId}</Text>
        </Card>
      );
    }

    return (
      <Card style={{ marginBottom: 16, background: '#fafafa' }}>
        <Title level={5}>Current Step: #{step.stepNumber}</Title>
        <Text level={4}>{matchedConfig.name}</Text>
        <Paragraph type="secondary">{matchedConfig.description}</Paragraph>

        <ApiForm
          hideFormButtons
          selectedApi={matchedConfig}
          initialRequestParams={allInOneOverrides[key] || {}}
          onValuesChange={(changedValues, allValues) => {
            setAllInOneOverrides((prev) => ({
              ...prev,
              [key]: allValues
            }));
          }}
        />
      </Card>
    );
  };

  // -------------------------------------------
  // UI Rendering
  // -------------------------------------------
  const toggleFullScreen = () => {
    setIsFullScreen((prev) => !prev);
  };

  return (
    <div style={{ padding: 16 }}>
      <Button
        icon={<ArrowLeftOutlined />}
        onClick={onClose}
        style={{ marginBottom: 16 }}
      >
        Back to Recipe List
      </Button>

      <Title level={3}>{recipe.name}</Title>
      <Text type="secondary" style={{ display: 'block', marginBottom: 16 }}>
        {recipe.description}
      </Text>

      <Tabs defaultActiveKey="auto">
        <TabPane tab="Auto-Run" key="auto">
          <Card>
            <Text>Run all steps sequentially with no extra user input.</Text>
            <div style={{ marginTop: 12 }}>
              <Button
                type="primary"
                icon={<PlayCircleOutlined />}
                onClick={handleAutoRunAll}
              >
                Run All (Auto)
              </Button>
            </div>
          </Card>
        </TabPane>

        <TabPane tab="All-in-One" key="allInOne">
          <p style={{ marginBottom: 16 }}>Edit each step’s data, then run them all in sequence.</p>
          {renderAllInOneForms()}
          <Button
            type="primary"
            icon={<PlayCircleOutlined />}
            onClick={handleAllInOneRunAll}
            style={{ marginTop: 12 }}
          >
            Run All Steps
          </Button>
        </TabPane>

        <TabPane tab="Step-by-Step" key="stepByStep">
          {renderStepByStepForm()}
          <div style={{ marginTop: 12 }}>
            <Button onClick={handleRunCurrentStep}>Run This Step</Button>
            <Button onClick={prevStep} style={{ marginLeft: 8 }} disabled={stepIndex === 0}>
              Prev
            </Button>
            <Button
              onClick={nextStep}
              style={{ marginLeft: 8 }}
              disabled={stepIndex >= recipe.steps.length - 1}
            >
              Next
            </Button>
          </div>
        </TabPane>
      </Tabs>

      {/* Console / Terminal */}
      <Card
        style={{
          marginTop: 16,
          backgroundColor: '#111',
          color: '#0f0',
          fontFamily: 'monospace',
          height: isFullScreen ? '100vh' : 400,
          width: isFullScreen ? '100vw' : 'auto',
          position: isFullScreen ? 'fixed' : 'relative',
          top: isFullScreen ? 0 : 'auto',
          left: isFullScreen ? 0 : 'auto',
          zIndex: isFullScreen ? 1000 : 'auto',
          overflow: 'hidden',
          transition: 'all 0.3s ease-in-out'
        }}
        bodyStyle={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          padding: 16
        }}
      >
        {/* Button Container */}
        <div
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
            display: 'flex',
            gap: 8
          }}
        >
          {/* Clear Logs */}
          <Button
            type="link"
            icon={<ClearOutlined />}
            onClick={handleClearLogs}
            style={{ color: '#fff', cursor: 'pointer' }}
            disabled={logs.length === 0}
          >
            Clear
          </Button>
          {/* Fullscreen Toggle */}
          <Button
            type="link"
            icon={isFullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
            onClick={toggleFullScreen}
            style={{ color: '#fff', cursor: 'pointer' }}
          >
            {isFullScreen ? 'Collapse' : 'Full Screen'}
          </Button>
        </div>

        {/* Logs & Command Output */}
        <div
          style={{
            flexGrow: 1,
            overflowY: 'auto',
            marginBottom: 8,
            paddingRight: 8
          }}
        >
          {logs.map((entry, idx) => (
            <div key={idx} style={{ marginBottom: 4 }}>
              <Text
                style={{
                  color:
                    entry.type === 'error'
                      ? 'red'
                      : entry.type === 'success'
                      ? 'lightgreen'
                      : entry.type === 'command'
                      ? '#0f0'
                      : '#0f0'
                }}
              >
                [{entry.timestamp}] {entry.msg}
              </Text>
            </div>
          ))}
          <div ref={logsEndRef} />
        </div>

        {/* Command Input */}
        <div style={{ marginTop: 'auto' }}>
          <Input
            ref={inputRef}
            placeholder="Enter a command (type help)"
            onPressEnter={handleCommandEnter}
            value={currentCommand}
            onChange={(e) => setCurrentCommand(e.target.value)}
            onKeyDown={handleKeyDown}
            prefix={<span style={{ color: '#0f0' }}>sandbox@specter:~% </span>}
            style={{
              backgroundColor: '#656a6d',
              color: '#0f0',
              border: 'none',
              outline: 'none'
            }}
            autoFocus
          />
        </div>
      </Card>
    </div>
  );
}

export default RecipeRunner;
