// src/components/RecipeList.js
import React, { useEffect, useState } from 'react';
import { Table, Button, Input, Space, Popconfirm, message } from 'antd';
import {
  PlusOutlined,
  EditOutlined,
  ReloadOutlined,
  DeleteOutlined,
  PlayCircleOutlined
} from '@ant-design/icons';
import axios from 'axios';

function RecipeList({ onAddRecipe, onEditRecipe, onRunRecipe }) {
  const [recipes, setRecipes] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    fetchRecipes();
    // eslint-disable-next-line
  }, [searchText]);

  const fetchRecipes = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/recipes`, {
        params: { name: searchText },
      });
      setRecipes(response.data);
      setPagination((prev) => ({
        ...prev,
        total: response.data?.total || response.data.length,
      }));
    } catch (error) {
      message.error('Failed to fetch recipes');
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_BASE_URL}/recipes/${id}`);
      setRecipes((prev) => prev.filter((r) => r._id !== id));
      message.success('Recipe deleted successfully');
    } catch (error) {
      console.error('Error deleting recipe:', error);
      message.error('Failed to delete recipe');
    }
  };

  const handleTableChange = (newPagination) => {
    setPagination(newPagination);
    // If you want to do server-side pagination, call fetch again
  };

  const handleRefresh = () => {
    fetchRecipes();
    message.success('Recipe list refreshed');
  };

  const columns = [
    {
      title: 'No',
      dataIndex: 'index',
      key: 'index',
      render: (_, __, index) =>
        (pagination.current - 1) * pagination.pageSize + index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => onEditRecipe(record)}
          >
            Edit
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this recipe?"
            onConfirm={() => handleDelete(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger icon={<DeleteOutlined />}>
              Delete
            </Button>
          </Popconfirm>
          <Button
            type="link"
            icon={<PlayCircleOutlined />}
            onClick={() => {
              // This calls the parent's handler
              onRunRecipe(record);
            }}
          >
            Run
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        <Input
          placeholder="Search by Recipe Name"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ width: '200px' }}
        />
        <div style={{ display: 'flex', gap: '8px' }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={onAddRecipe}
          >
            Add Recipe
          </Button>
          <Button icon={<ReloadOutlined />} onClick={handleRefresh}>
            Refresh
          </Button>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={recipes}
        rowKey="_id"
        onChange={handleTableChange}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'],
          showTotal: (total) => `Total Recipes: ${total}`,
          onShowSizeChange: (current, size) => {
            setPagination((prev) => ({ ...prev, pageSize: size, current: 1 }));
            fetchRecipes();
          },
        }}
      />
    </div>
  );
}

export default RecipeList;
