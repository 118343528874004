// src/utils/requestBuilder.js

/**
 * Builds the request data object based on form values and API parameters.
 *
 * @param {Object} values - The form values from ApiForm or RecipeRunner.
 * @param {Array} parameters - The API parameters from ApiConfig.
 * @param {Object} selectedApi - The selected API configuration.
 * @param {Boolean} useRawBody - Flag indicating if raw body mode is used.
 * @param {String} rawBodyValue - The raw JSON body as a string.
 * @returns {Object} - The structured request data.
 */
export const buildRequestData = (values, parameters, selectedApi, useRawBody, rawBodyValue) => {
    console.log('buildRequestData received values:', values);
    const requestData = {};
  
    // Helper function to filter out null and empty values
    const filterEmptyValues = (obj) => {
      if (Array.isArray(obj)) {
        return obj.map((item) => filterEmptyValues(item));
      } else if (typeof obj === 'object' && obj !== null) {
        return Object.entries(obj).reduce((acc, [key, value]) => {
          if (value !== null && value !== undefined && value !== '') {
            acc[key] = typeof value === 'object' ? filterEmptyValues(value) : value;
          }
          return acc;
        }, {});
      }
      return obj;
    };
  
    // Initialize sections
    const queryParams = {};
    const pathParams = {};
    const headerParams = {};
    const bodyParams = {};
    const formDataParams = {};
  
    // Handle parameters
    parameters.forEach((param) => {
      const fieldName = [param.parameterType, param.name];
      const includeParam = getNestedValue(values, ['include', ...fieldName]);
      if (includeParam) {
        let value = getNestedValue(values, ['values', ...fieldName]);
  
        if (value !== undefined && value !== null && value !== '') {
          // Handle date conversion
          if (param.dataType === 'date' && value) {
            value = value.format('YYYY-MM-DD');
          }
  
          const paramType = param.parameterType;
          if (paramType === 'query') {
            setNestedValue(queryParams, param.fieldPath || [param.name], value);
          } else if (paramType === 'path') {
            setNestedValue(pathParams, param.fieldPath || [param.name], value);
          } else if (paramType === 'header') {
            setNestedValue(headerParams, param.fieldPath || [param.name], value);
          } else if (paramType === 'body') {
            if (!useRawBody) {
              setNestedValue(bodyParams, param.fieldPath || [param.name], value);
            }
          } else if (paramType === 'form-data') {
            if (param.formDataFields) {
              param.formDataFields.forEach((field) => {
                const includeField = getNestedValue(values, ['include', ...fieldName, field.fieldName]);
  
                if (includeField) {
                  const fieldValue = getNestedValue(values, ['values', ...fieldName, field.fieldName]);
  
                  if (field.fieldType === 'file') {
                    formDataParams[field.fieldName] = fieldValue?.[0]?.originFileObj;
                  } else {
                    formDataParams[field.fieldName] = fieldValue;
                  }
                }
              });
            } else {
              formDataParams[param.name] = value;
            }
          }
        }
      }
    });
  
    // Apply filter to remove empty or null values
    const filteredQueryParams = filterEmptyValues(queryParams);
    const filteredPathParams = filterEmptyValues(pathParams);
    const filteredHeaderParams = filterEmptyValues(headerParams);
    const filteredFormDataParams = filterEmptyValues(formDataParams);
  
    // Assign filtered sections to requestData if they have values
    if (Object.keys(filteredQueryParams).length > 0) {
      requestData.query = filteredQueryParams;
    }
    if (Object.keys(filteredPathParams).length > 0) {
      requestData.path = filteredPathParams;
    }
    if (Object.keys(filteredHeaderParams).length > 0) {
      requestData.headers = filteredHeaderParams;
    }
    if (Object.keys(filteredFormDataParams).length > 0) {
      requestData.formData = filteredFormDataParams;
    }
  
    // Handle body parameters
    if (useRawBody) {
      try {
        const parsedBody = JSON.parse(rawBodyValue);
        requestData.body = parsedBody;
      } catch (error) {
        throw new Error('Invalid JSON in raw body.');
      }
    } else {
      const filteredBodyParams = filterEmptyValues(bodyParams);
      if (Object.keys(filteredBodyParams).length > 0) {
        requestData.body = filteredBodyParams;
      }
    }
  
    // Handle attributes
    if (!useRawBody && selectedApi?.attributes && selectedApi.attributes.length > 0) {
      const selectedAttributes = [];
      selectedApi.attributes.forEach((attr, index) => {
        const isSelected = values.attributes?.[`attr_${index}`];
        if (isSelected) {
          selectedAttributes.push(attr.name);
        }
      });
      if (selectedAttributes.length > 0) {
        requestData.attributes = selectedAttributes;
      }
    }
  
    return requestData;
  };
  
  /**
   * Sets a nested value within an object based on the provided path.
   *
   * @param {Object} obj - The object to modify.
   * @param {Array} path - An array representing the path to the key.
   * @param {any} value - The value to set.
   */
  const setNestedValue = (obj, path, value) => {
    let current = obj;
    for (let i = 0; i < path.length; i++) {
      const key = path[i];
      if (i === path.length - 1) {
        current[key] = value;
      } else {
        if (!current[key]) current[key] = {};
        current = current[key];
      }
    }
  };
  
  /**
   * Retrieves a nested value from an object based on the provided path.
   *
   * @param {Object} obj - The object to traverse.
   * @param {Array} path - An array representing the path to the key.
   * @returns {any} - The value at the specified path or undefined.
   */
  const getNestedValue = (obj, path) => {
    return path.reduce(
      (acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined),
      obj
    );
  };
  