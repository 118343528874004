// src/components/RecipeForm.js
import React, { useEffect, useState } from 'react';
import {
  Card,
  Form,
  Input,
  Button,
  Select,
  Collapse,
  Space,
  Row,
  Col,
  Typography,
  Divider,
  InputNumber,
  message,
} from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Panel } = Collapse;
const { Title } = Typography;
const { Option } = Select;

/**
 * Props:
 * - initialValues: the existing recipe data if editing
 * - isEditMode: boolean
 * - onSubmit: callback for final form submission
 * - onCancel: callback to cancel
 */
function RecipeForm({ initialValues = {}, isEditMode = false, onSubmit, onCancel }) {
  const [form] = Form.useForm();
  const [apiConfigs, setApiConfigs] = useState([]);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    // Pre-fill the form if we have initial values (i.e. editing)
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  useEffect(() => {
    // Fetch all ApiConfigs so user can select them in each step
    fetchApiConfigs();
    // eslint-disable-next-line
  }, []);

  const fetchApiConfigs = async () => {
    try {
      const res = await axios.get(`${API_BASE_URL}/config`);
      setApiConfigs(res.data); // This is an array of all API configs
    } catch (err) {
      console.error('Failed to fetch API configs:', err);
      message.error('Could not load API configs');
    }
  };

  const handleFinish = (values) => {
    // If you want to parse the JSON for path/body overrides, you can do so here
    // Or keep them as strings and parse them on the server
    onSubmit(values);
  };

  return (
    <Row gutter={16} style={{ padding: '20px' }}>
      <Col span={24}>
        <Card
          title={isEditMode ? 'Edit Recipe' : 'Create Recipe'}
          bordered={false}
          style={{ boxShadow: '0 1px 4px rgba(0,0,0,0.1)' }}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={handleFinish}
            initialValues={initialValues}
          >
            <Title level={4} style={{ marginBottom: 16 }}>
              Recipe Information
            </Title>

            <Form.Item
              name="name"
              label="Recipe Name"
              rules={[{ required: true, message: 'Please enter a recipe name' }]}
            >
              <Input placeholder="e.g. Competition Flow" />
            </Form.Item>

            <Form.Item name="description" label="Description">
              <Input.TextArea
                placeholder="Describe what this recipe does..."
                autoSize={{ minRows: 2, maxRows: 5 }}
              />
            </Form.Item>

            <Form.Item name="tags" label="Tags (optional)">
              <Select
                mode="tags"
                placeholder="Add tags to categorize this recipe"
                style={{ width: '100%' }}
              />
            </Form.Item>

            <Divider />

            <Title level={4} style={{ marginBottom: 16 }}>
              Steps
            </Title>

            <Form.List name="steps">
              {(fields, { add, remove }) => (
                <>
                  {fields.length === 0 && (
                    <Card style={{ marginBottom: 16, backgroundColor: '#fafafa' }}>
                      <p style={{ margin: 0 }}>
                        No steps added yet. Click <strong>"Add Step"</strong> below.
                      </p>
                    </Card>
                  )}
                  {fields.map(({ key, name, ...restField }, idx) => (
                    <Card
                      key={key}
                      style={{ marginBottom: 16, backgroundColor: '#fafafa' }}
                    >
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Title level={5} style={{ marginBottom: 0 }}>
                          Step {idx + 1}
                        </Title>
                        <Button
                          type="text"
                          danger
                          onClick={() => remove(name)}
                          icon={<MinusCircleOutlined />}
                        >
                          Remove
                        </Button>
                      </div>
                      <Divider style={{ margin: '8px 0' }} />

                      <Row gutter={16}>
                        <Col span={8}>
                          <Form.Item
                            {...restField}
                            name={[name, 'stepNumber']}
                            label="Step Number"
                            rules={[{ required: true, message: 'Step number is required' }]}
                          >
                            <InputNumber min={1} style={{ width: '100%' }} />
                          </Form.Item>
                        </Col>

                        <Col span={16}>
                          <Form.Item
                            {...restField}
                            label="API Config"
                            name={[name, 'apiConfigId']}
                            rules={[{ required: true, message: 'Select an API config' }]}
                          >
                           <Select
  placeholder="Pick an API for this step"
  showSearch
  optionFilterProp="children"
  filterOption={(input, option) =>
    option?.children
      ?.toString()
      .toLowerCase()
      .includes(input.toLowerCase())
  }
>
  {apiConfigs.map((cfg) => (
    <Option key={cfg._id} value={cfg._id}>
      {cfg.name} ({cfg.method} {cfg.route})
    </Option>
  ))}
</Select>

                          </Form.Item>
                        </Col>
                      </Row>

                      <Divider dashed />

                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            {...restField}
                            label="Path Overrides (JSON)"
                            name={[name, 'requestOverrides', 'path']}
                            tooltip="Specify path parameters as JSON, e.g. { 'matchId': 'abc123' }"
                          >
                            <Input.TextArea
                              rows={3}
                              placeholder='e.g. { "id": "123" }'
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            {...restField}
                            label="Body Overrides (JSON)"
                            name={[name, 'requestOverrides', 'body']}
                            tooltip="Specify body parameters as JSON, e.g. { 'players': ['p1','p2'] }"
                          >
                            <Input.TextArea
                              rows={3}
                              placeholder='e.g. { "key": "value" }'
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Collapse style={{ marginTop: 8 }} bordered={false} defaultActiveKey={[]}>
                        <Panel header="Advanced: Dynamic Mappings" key="dm-panel">
                        <Form.List name={[name, 'dynamicMappings']}>
  {(mapFields, { add: addMap, remove: removeMap }) => (
    <>
      {mapFields.map(({ key: mapKey, name: mapName, ...restMap }) => (
        <Row
          key={mapKey}
          gutter={8}
          align="middle"
          style={{ marginBottom: 8 }}
        >
          <Col span={6}>
            {/* 1) FROM STEP */}
            <Form.Item
              {...restMap}
              label="From Step"
              name={[mapName, 'fromStep']}
              rules={[{ required: true, message: 'Specify which step number' }]}
            >
              <InputNumber
                placeholder="E.g. 1"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            {/* 2) FROM PATH */}
            <Form.Item
              {...restMap}
              label="From Path"
              name={[mapName, 'fromPath']}
              rules={[{ required: true, message: 'Specify fromPath' }]}
              tooltip="e.g. response.data.someField"
            >
              <Input placeholder="response.data.someField" />
            </Form.Item>
          </Col>

          <Col span={8}>
            {/* 3) TO */}
            <Form.Item
              {...restMap}
              label="To"
              name={[mapName, 'to']}
              rules={[{ required: true, message: 'Specify where to place the data' }]}
              tooltip="e.g. body.matchSessionId, path.myParam, query.userId"
            >
              <Input placeholder="body.myParam" />
            </Form.Item>
          </Col>

          <Col span={2}>
            <Button
              type="text"
              icon={<MinusCircleOutlined />}
              danger
              onClick={() => removeMap(mapName)}
            />
          </Col>
        </Row>
      ))}

      <Form.Item>
        <Button
          type="dashed"
          icon={<PlusOutlined />}
          onClick={() => addMap()}
        >
          Add Mapping
        </Button>
      </Form.Item>
    </>
  )}
</Form.List>

                        </Panel>
                      </Collapse>
                    </Card>
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      icon={<PlusOutlined />}
                      onClick={() => add()}
                      style={{ width: '100%' }}
                    >
                      Add Step
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>

            <Divider />

            <Space style={{ marginTop: 16 }}>
              <Button type="primary" htmlType="submit">
                {isEditMode ? 'Update Recipe' : 'Save Recipe'}
              </Button>
              <Button onClick={onCancel}>Cancel</Button>
            </Space>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}

export default RecipeForm;
